/* .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 100% !important;
    height: 100% !important;
} */

@media print {
    .pdf-container {
        display: none;
    }
}

.pg-viewer-wrapper {
    height: 90vh !important;
}
