@import url(https://fonts.googleapis.com/css2?family=Sora&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'Sora', sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* body {
    background-color: #E0E0E0;
} */
.ql-editor{
    min-height: 120px;
}

/* .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 100% !important;
    height: 100% !important;
} */

@media print {
    .pdf-container {
        display: none;
    }
}

.pg-viewer-wrapper {
    height: 90vh !important;
}

.ql-editor{
    min-height: 80px;
}


.ql-snow .ql-editor pre.ql-syntax {
    background-color: #E0E0E0;
    color: black;
    overflow: visible;
}
